import axios from "axios";

export default {
    postUserLogin: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}user/login`,
            withCredentials: true,
            data: {
                email: payload.email,
                password: payload.password,
                role: 500,
            }
        })
            .then(res => {
                console.log('Status ', res.data.user.validation)
                if (res.data.errors)
                    reject(0, res.data.errors)
                else
                    resolve(res.data)
            })
            .catch(err => {
                if (reject) reject(1, err)
            })
    },
    postTokenPwdMail:(payload,resolve,reject)=>{
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}resend/tokenPasswordMail`,
            data: {
                email:payload.email
            }
        })
            .then(res => {
                console.log(res)
                console.log('Status ', res.data.user.validation)
                if (res.data.errors)
                    reject(0, res.data.errors)
                else
                    resolve(res.data)
            })
            .catch(err => {
                if (reject) reject(1, err)
            })
    },
    postTokenPwd:(payload,resolve,reject)=>{
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}resend/tokenPassword`,
            data: {
                email:payload.email,
                token:payload.token
            }
        })
            .then(res => {
                console.log(res)
                console.log('Status ', res.data.user.validation)
                if (res.data.errors)
                    reject(0, res.data.errors)
                else
                    resolve(res.data)
            })
            .catch(err => {
                if (reject) reject(1, err)
            })
    },
    putPwdUser:(payload,resolve,reject)=>{
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}user/update/password`,
            data: {
                email:payload.email,
                password:payload.password
            }
        })
            .then(res => {
                if (res.data.errors)
                    reject(0, res.data.errors)
                else
                    resolve(res.data)
            })
            .catch(err => {
                if (reject) reject(1, err)
            })
    },
    putPwdUserCompany:(payload,resolve,reject)=>{
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}user/update/password`,
            data: {
                id:payload.id,
                email:payload.email,
                password:payload.password,
                validation:payload.validation
            }
        })
            .then(res => {
                if (res.data.errors)
                    reject(0, res.data.errors)
                else
                    resolve(res.data)
            })
            .catch(err => {
                if (reject) reject(1, err)
            })
    },
    postRoleUser:(payload,resolve,reject)=>{
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}user/role`,
            data: {
                token:payload.token
            }
        })
            .then(res => {
                if (res.data.errors)
                    reject(0, res.data.errors)
                else
                    resolve(res.data)
            })
            .catch(err => {
                if (reject) reject(1, err)
            })
    },
    getUserProfile: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}user/info`,
            withCredentials: true,
            data: {
                token: payload.token
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
}