import React, {useState, useEffect, useRef} from 'react';
import {useNavigate} from 'react-router-dom'

import ApiDatabase from "server"

//Import Component
import NavbarAuth from "components/navBar";
import useModal from "components/modal/useModal";
import {Information, Mail, NoShowPassword, ShowPassword} from "assets/icons";
import Button from "components/button";
import UseContext from "hooks/useContext";
import useAuth from "hooks/useAuth";

const EMAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

const Login = () => {

    const userRef = useRef();
    const {updateUser,typeNav,email,setEmail}=UseContext()
    const {setAuth}=useAuth()
    const navigate = useNavigate();
    const [validEmail, setValidEmail] = useState(false);
    const [password, setPassword] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const {toggle} = useModal()
    const [valid, setValid] = useState(false)
    // State show Password
    const [showPassword, setShowPassword] = useState(false);
    const handleShowPassword = () => setShowPassword(!showPassword);

    useEffect(() => {
        setErrMsg("");
    }, [email, password]);
    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email))
    }, [email])

    const handleUserInput = (e) => setEmail(e.target.value);

    const handlePasswordInput = (e) => setPassword(e.target.value);

    const handleForgot = () => {
        if(typeNav ===2 )navigate('/forgot/entreprise')
        else navigate('/forgot/interimaire')
    }

    const handleSubmit =  (e) => {
        e.preventDefault();
        setValid(false)
        ApiDatabase.postUserLogin({email, password}, (data) => {
                if (data.xsrfToken) {
                    updateUser(data.xsrfToken, data.user.role)
                    setAuth({roles: data.user.role, user: email})
                    if (data.user.role >= 500) {
                        console.log('admin', process.env.REACT_APP_TOKEN_ADMIN+''+data.xsrfToken)
                        setValid(false)
                        window.location.href=`${process.env.REACT_APP_TOKEN_ADMIN}${data.xsrfToken}`;
                    } else {
                        setValid(true)
                        console.log('Erreur dans le login role')
                    }
                }
            },
            (status, err) => {
                setValid(true)
                if (status === 0) setValidEmail(false)
                console.log(err)
            })
    }

    return (
        <>
            <div className="flex h-screen overflow-hidden">
                {/* Content area */}
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    <main className="h-screen flex items-center justify-center">
                        {/* Main Content */}
                        <div
                            className=" flex items-center justify-center py-12 px-5 mm:px-6 lg:px-8">
                            <div className="max-w-md w-full space-y-8">
                                <div>
                                    <h2 className="mt-6 text-center text-4xl font-medium text-[#111827]">
                                        Se connecter
                                    </h2>

                                </div>
                                {valid ?
                                    <p className='text-red-500 flex flex-row gap-4 items-center justify-center'>
                                        <span>
                                              <Information wh={15} color={'#EF4444'}/>
                                         </span>
                                        Votre email ou mot de passe est incorrect</p> : ''
                                }
                                <div
                                    className="rounded bg-white md:max-w-tab tab:w-[30rem] overflow-hidden  p-2 mm:p-5 ">
                                    <form className="space-y-4">
                                        <div className="rounded-md shadow-sm -space-y-px">
                                            <div className="grid grid-cols-12 gap-6">
                                                <div className="col-span-12">
                                                    <label
                                                        htmlFor="email_address"
                                                        className="block text-sm font-medium font-Poppins text-gray-500"
                                                    >
                                                        E-mail
                                                    </label>
                                                    <div className="relative text-gray-500">
                                                        <input
                                                            name="email"
                                                            ref={userRef}
                                                            value={email}
                                                            onChange={handleUserInput}
                                                            className="w-full h-10 pl-8 pr-3 text-base placeholder-gray-600 border rounded-lg "
                                                            type="email"
                                                            required
                                                        />
                                                        <div
                                                            className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                                                            <Mail wh={18} color={'#666666'}
                                                                  strokeW={2}/>

                                                        </div>
                                                    </div>
                                                    <p
                                                        className={
                                                            email && !validEmail
                                                                ? "text-[#FF9600] flex flex-row gap-4 items-center"
                                                                : "hidden"
                                                        }
                                                    >
                                                          <span>
                                                                <Information wh={20}
                                                                             color={'#FF9600'}/>
                                                          </span>
                                                        Format de l'email incorrect
                                                    </p>
                                                </div>
                                                <div className="col-span-12">
                                                    <label
                                                        className="block ft-sm font-medium font-Poppins text-gray-500">
                                                        Mot de passe
                                                    </label>
                                                    <div className="relative text-[#6B7280]">
                                                        <input
                                                            name="password"
                                                            value={password}
                                                            onChange={handlePasswordInput}
                                                            className={`w-full h-10 pl-3 pr-8 text-base border rounded-lg  `}
                                                            type={showPassword ? "text" : "password"}
                                                            required
                                                        />
                                                        <div
                                                            className="absolute inset-y-0 right-0 flex items-center px-2 cursor-pointer"
                                                            onClick={handleShowPassword}
                                                        >
                                                            {showPassword ? (
                                                                <NoShowPassword wh={18}
                                                                                color={'#666666'}
                                                                                strokeW={2}/>
                                                            ) : (
                                                                <ShowPassword wh={18}
                                                                              color={'#666666'}
                                                                              strokeW={2}/>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className={'ml-auto'}>
                                                <Button size={'LG'} onClick={handleSubmit}>
                                                    Se connecter
                                                </Button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
};

export default Login;