import React, {useState, useContext} from 'react';

import useModal from "components/modal/useModal";
import ApiDatabase from 'server'
import {toast} from "react-toastify";
import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import Button from "components/button";
import UseContext from "hooks/useContext";


const Tokenemail = () => {
    const [tokenMail, setTokenMail] = useState('')
    const {toggle} = useModal()
    const {refreshLoad,objID,objType} = UseContext()
    const [validToken, setValidToken] = useState(false)
    console.log(objType)
    const handleMail = async (e) => {
        e.preventDefault()
        ApiDatabase.postResendEmailToken(
            {email: objID},
            (data) => {
                toast.success('Un email a été envoyé')
            },
            (err) => {
                console.log(err)
            }
        )

    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        ApiDatabase.postEmailRefresh(
            {tokenMail, email: objID,validation:objType,token: localStorage.getItem('xsrfToken')},
            (data) => {
                refreshLoad()
                toggle()
                toast.success('Modification prise en compte')
            },
            (err) => {
                console.log(err)
            }
        )
    }
    return (
        <>
            <Modalheader hide={toggle} hideShow={false}>
                Validation du code d'inscription
            </Modalheader>
            {/*Body*/}
            <ModalBody>
                <form className='flex flex-col w-full'>
                    <label htmlFor='token'>Validation du code :</label>
                    <p className={validToken ?
                        "text-red-500 flex flex-row gap-4 items-center"
                        : 'hidden'
                    }>
                                            <span>
                                                <svg
                                                    width="14"
                                                    height="14"
                                                    viewBox="0 0 14 14"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                 <path
                                                     d="M7.00004 13.6667C3.31804 13.6667 0.333374 10.682 0.333374 7C0.333374 3.318 3.31804 0.333336 7.00004 0.333336C10.682 0.333336 13.6667 3.318 13.6667 7C13.6667 10.682 10.682 13.6667 7.00004 13.6667ZM6.33337 9V10.3333H7.66671V9H6.33337ZM6.33337 3.66667V7.66667H7.66671V3.66667H6.33337Z"
                                                     fill="#FF9600"
                                                 />
                                                     </svg>
                                                </span>
                        Veuillez indiquer un token valide.
                    </p>
                    <input
                        className='w-full h-10 pl-3 pr-8 ft-b placeholder-gray-600 border rounded-lg '
                        name='token'
                        onChange={(e) => setTokenMail(e.target.value)}
                        value={tokenMail}
                    />
                </form>
                <span
                    className='flex justify-center items-center mt-4 ft-xs cursor-pointer'
                    onClick={handleMail}>Renvoyer le code de vérification</span>
            </ModalBody>
            <ModalFooter>
                <Button onClick={handleSubmit} size={'LG'}>Confirmer</Button>
            </ModalFooter>
        </>
    );
};

export default Tokenemail;