import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import Login from "pages/Login";
import Layout from "pages/layout";


const ConnectionRoutes = () => {

    return (
        <>
            <Routes>
                {/*Route public*/}
                <Route path='/' element={<Layout/>}>
                    {/*<Route element={<PublicRoutes/>}>*/}
                    <Route path='login/' element={<Login/>}/>
                    <Route path={""} element={<Navigate to="/login"/>} />
                    <Route path={"*"} element={<Navigate to="/login"/>} />
                </Route>
            </Routes>
        </>
    );
};

export default ConnectionRoutes;