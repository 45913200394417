import {Outlet, useLocation, useParams} from "react-router-dom"
import ApiDatabase from 'server'
import {useEffect} from "react";
import UseContext from "hooks/useContext";
const Layout = () => {
    const {updateUser}=UseContext()
    const location = useLocation();
    const tokenURL=location.search.split('=')[1]

    useEffect(()=>{
        if(tokenURL){
            ApiDatabase.postRoleUser({token:tokenURL},
                (data)=>{
                    updateUser(data.token_refresh,data.role)
                    window.location.replace('/dashboard')
                },
                (err)=>{
                    console.log(err)})
        }
    },[])

    return (
        <>
            <Outlet />
        </>
    )
}

export default Layout